import { createContext, useContext } from "react";
import OAuthClient from "./oauth";

interface ContextType {
  client: OAuthClient;
}

const context = createContext<ContextType>({} as any);

export function useOAuthClient() {
  const { client } = useContext(context);

  return client;
}

const { Consumer, Provider } = context;

export { Consumer, Provider };
