import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import { Resolver } from "../types";
import { writeAuthenticationInfo } from "./getAuthenticationInfo";
import SET_AUTHENTICATION_INFO from "./setAuthenticationInfo.graphql";
import {
  SetAuthenticationInfo,
  SetAuthenticationInfoVariables,
} from "./types/SetAuthenticationInfo";

export { SET_AUTHENTICATION_INFO };

export function useSetAuthenticationInfo(
  options?: MutationHookOptions<
    SetAuthenticationInfo,
    SetAuthenticationInfoVariables
  >
) {
  return useMutation<SetAuthenticationInfo, SetAuthenticationInfoVariables>(
    SET_AUTHENTICATION_INFO,
    options
  );
}

const setAuthenticationInfo: Resolver<any, SetAuthenticationInfoVariables> = (
  _rootValue,
  { accessToken, expireInSeconds, refreshToken, tokenType },
  { cache }
) => {
  writeAuthenticationInfo(cache, {
    authenticationInfo: {
      __typename: "AuthenticationInfo",
      accessToken,
      creationTime: new Date().toISOString(),
      expireInSeconds,
      refreshToken,
      tokenType,
    },
  });

  return true;
};

export function addSetAuthResolvers<T = object>(client: ApolloClient<T>) {
  client.addResolvers({
    Mutation: {
      setAuthenticationInfo,
    },
  });
}
