import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import idx from "idx";
import ApolloClient from "apollo-client";
import { Resolver } from "../types";
import { GetOption, GetOptionVariables } from "./types/GetOption";
import GET_OPTION from "./getOption.graphql";
import { readOption } from "./getOptions";

export function useOption(
  options?: QueryHookOptions<GetOption, GetOptionVariables>
) {
  const result = useQuery(GET_OPTION, { fetchPolicy: "no-cache", ...options });
  const { data } = result;
  const optionValue = idx(data, x => x.option) || undefined;

  return {
    optionValue,
    ...result,
  };
}

const option: Resolver<{}, GetOptionVariables, string | null> = (
  _root,
  { key },
  { cache }
) => {
  return readOption(cache, key);
};

export function addOptionResolver<T = object>(client: ApolloClient<T>) {
  client.addResolvers({
    Query: {
      option,
    },
  });
}
