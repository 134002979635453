import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import idx from "idx";
import { Resolver } from "../types";
import { SetOption, SetOptionVariables } from "./types/SetOption";
import SET_OPTION from "./setOption.graphql";
import { readOptions, writeOptions } from "./getOptions";

export function useOptionMutation(
  options?: MutationHookOptions<SetOption, SetOptionVariables>
) {
  return useMutation(SET_OPTION, options);
}

const setOption: Resolver<{}, SetOptionVariables, boolean> = (
  _root,
  { key, value },
  { cache }
) => {
  const data = readOptions(cache);
  const options = idx(data, x => x.options) || [];

  writeOptions(cache, {
    options: [
      ...options.filter(x => x.key !== key),
      { __typename: "OptionKeyValuePair", key, value: value as string },
    ],
  });

  return true;
};

export function addSetOptionResolver<T = object>(client: ApolloClient<T>) {
  client.addResolvers({
    Mutation: {
      setOption,
    },
  });
}
