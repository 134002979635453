import ApolloClient from "apollo-client";
import { addOptionsEvents } from "./getOptions";
import { addOptionResolver } from "./getOption";
import { addSetOptionResolver } from "./setOption";

export * from "./getOption";
export * from "./getOptions";
export * from "./setOption";

export function optionsConfig<T = object>(client: ApolloClient<T>) {
  addOptionResolver(client);
  addSetOptionResolver(client);
  addOptionsEvents(client);
}
