import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import idx from "idx";
import { Resolver } from "../types";
import { readAuthenticationInfo } from "./getAuthenticationInfo";
import IS_AUTHENTICATED from "./isAuthenticated.graphql";
import { IsAuthenticated as IsAuthenticatedData } from "./types/IsAuthenticated";
import { useMemo } from "react";

export function useIsAuthenticated(
  options?: QueryHookOptions<IsAuthenticatedData>
) {
  const { called, loading, data, ...result } = useQuery<IsAuthenticatedData>(
    IS_AUTHENTICATED,
    options
  );

  const loaded = useMemo(() => called && !loading, [called, loading]);
  const isAuthenticated = useMemo(
    () => idx(data, x => x.isAuthenticated) || false,
    [data]
  );

  return {
    called,
    loading,
    loaded,
    isAuthenticated,
    ...result,
  };
}

const isAuthenticated: Resolver = (_rootValue, _args, { cache }) => {
  const data = readAuthenticationInfo(cache);

  return Boolean(idx(data, x => x.authenticationInfo.accessToken));
};

export function addIsAuthenticatedResolver<T = object>(
  client: ApolloClient<T>
) {
  client.addResolvers({
    Query: {
      isAuthenticated,
    },
  });
}

export { IS_AUTHENTICATED };
