import React, { FC, Children, useMemo } from "react";
import { Provider } from "./context";
import OAuthClient from "./oauth";

interface OAuthClientProviderProps {
  client: OAuthClient;
}

const OAuthClientProvider: FC<OAuthClientProviderProps> = ({
  client,
  children
}) => {
  const value = useMemo(() => ({ client }), [client]);

  return <Provider value={value}>{Children.only(children)}</Provider>;
};

export default OAuthClientProvider;
