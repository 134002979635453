import { onError } from "apollo-link-error";
import { Observable, Operation, NextLink, FetchResult } from "apollo-link";
import idx from "idx";
import { navigate } from "gatsby";
import { readAuthenticationInfo, writeAuthenticationInfo } from "./local";
import routes from "routes";
import { OAuthClient } from "components/oauth";

export default function createErrorLink(oauthClient: OAuthClient) {
  const refreshToken = async (operation: Operation) => {
    const { cache } = operation.getContext();
    const data = readAuthenticationInfo(cache);
    const refreshToken = idx(data, x => x.authenticationInfo.refreshToken);
    if (refreshToken) {
      const response = await oauthClient.refresh(refreshToken);
      if (response.status === 200) {
        const {
          access_token,
          token_type,
          expires_in,
          refresh_token,
        } = await response.json();

        writeAuthenticationInfo(cache, {
          authenticationInfo: {
            __typename: "AuthenticationInfo",
            accessToken: access_token,
            creationTime: new Date().toISOString(),
            expireInSeconds: expires_in,
            refreshToken: refresh_token || refreshToken,
            tokenType: token_type,
          },
        });

        return true;
      }
    }

    return false;
  };

  const handleUnauthorized = (operation: Operation, forward: NextLink) => {
    return new Observable<FetchResult>(observer => {
      let sub: ZenObservable.Subscription | undefined;

      refreshToken(operation).then(success => {
        if (success) {
          sub = forward(operation).subscribe(observer);
        } else {
          observer.error(new Error("Authorization token refresh failed"));
        }
      });

      return () => {
        if (sub) sub.unsubscribe();
      };
    });
  };

  return onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      for (const {
        message,
        locations,
        path,
        extensions: { code = "" } = {},
      } of graphQLErrors) {
        const normalizedCode = code.toUpperCase();
        if (normalizedCode === "UNAUTHORIZED") {
          return handleUnauthorized(operation, forward);
        }

        if (normalizedCode === "FORBIDDEN") {
          navigate(routes.login);
          return;
        }

        console.log(
          `[GraphQL error]: Code: ${code}, Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    } else if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });
}
