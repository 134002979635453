/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/assets/styles/global.scss";
import { wrapRootElementFactory } from "./src/app";
import { baseUrl } from "./app.config";

export const wrapRootElement = wrapRootElementFactory({
  baseUrl,
});
