import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { ApolloCache } from "apollo-cache";
import ApolloClient from "apollo-client";
import GET_AUTHENTICATION_INFO from "./getAuthenticationInfo.graphql";
import { GetAuthenticationInfo } from "./types/GetAuthenticationInfo";

export { GET_AUTHENTICATION_INFO };

export function useAuthenticationInfo(
  options?: QueryHookOptions<GetAuthenticationInfo>
) {
  return useQuery<GetAuthenticationInfo>(GET_AUTHENTICATION_INFO, options);
}

export function readAuthenticationInfo<TSerialized = object>(
  cache: ApolloCache<TSerialized>
) {
  return cache.readQuery<GetAuthenticationInfo>({
    query: GET_AUTHENTICATION_INFO,
  });
}

export function writeAuthenticationInfo<TSerialized = object>(
  cache: ApolloCache<TSerialized>,
  data: GetAuthenticationInfo = {
    authenticationInfo: {
      __typename: "AuthenticationInfo",
      accessToken: "",
      creationTime: "",
      expireInSeconds: 0,
      refreshToken: "",
      tokenType: "",
    },
  }
) {
  cache.writeQuery<GetAuthenticationInfo>({
    query: GET_AUTHENTICATION_INFO,
    data,
  });
}

export function addAuthEvents<T = object>(client: ApolloClient<T>) {
  const { cache } = client;

  let shouldWriteData = false;
  try {
    if (!readAuthenticationInfo(cache)) {
      shouldWriteData = true;
    }
  } catch {
    shouldWriteData = true;
  }

  if (shouldWriteData) {
    writeAuthenticationInfo(client.cache);
  }

  client.onClearStore(() => {
    writeAuthenticationInfo(client.cache);

    return Promise.resolve();
  });

  client.onResetStore(() => {
    writeAuthenticationInfo(client.cache);

    return Promise.resolve();
  });
}
