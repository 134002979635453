import { useQuery, QueryHookOptions } from "@apollo/react-hooks";
import { ApolloCache } from "apollo-cache";
import ApolloClient from "apollo-client";
import idx from "idx";
import GET_OPTIONS from "./getOptions.graphql";
import { GetOptions } from "./types/GetOptions";

export function useOptions(options?: QueryHookOptions<GetOptions>) {
  return useQuery<GetOptions>(GET_OPTIONS, options);
}

export function readOptions<T = object>(cache: ApolloCache<T>) {
  return cache.readQuery<GetOptions>({ query: GET_OPTIONS });
}

export function readOption<T = object>(cache: ApolloCache<T>, key: string) {
  const data = readOptions(cache);
  const options = idx(data, x => x.options);
  if (options) {
    const option = options.find(x => x.key === key);
    return option ? option.value : null;
  }
  return null;
}

export function writeOptions<T = object>(
  cache: ApolloCache<T>,
  data: GetOptions = { options: [] }
) {
  cache.writeQuery<GetOptions>({ query: GET_OPTIONS, data });
}

export function addOptionsEvents<T = object>(client: ApolloClient<T>) {
  const { cache } = client;

  let shouldWriteData = false;
  try {
    if (!readOptions(cache)) {
      shouldWriteData = true;
    }
  } catch {
    shouldWriteData = true;
  }

  if (shouldWriteData) {
    writeOptions(cache);
  }

  client.onClearStore(() => {
    writeOptions(cache);

    return Promise.resolve();
  });

  client.onResetStore(() => {
    writeOptions(cache);

    return Promise.resolve();
  });
}
