import ApolloClient from "apollo-client";
import { addIsAuthenticatedResolver } from "./isAuthenticated";
import { addSetAuthResolvers } from "./setAuthenticationInfo";
import { addAuthEvents } from "./getAuthenticationInfo";

export * from "./createAuthLink";
export * from "./getAuthenticationInfo";
export { IS_AUTHENTICATED, useIsAuthenticated } from "./isAuthenticated";
export {
  SET_AUTHENTICATION_INFO,
  useSetAuthenticationInfo,
} from "./setAuthenticationInfo";

export function authConfig<T = object>(client: ApolloClient<T>) {
  addIsAuthenticatedResolver(client);
  addSetAuthResolvers(client);
  addAuthEvents(client);
}
