export function formUrlEncodedSerialize(data: any) {
  const formBody: string[] = [];
  for (const property in data) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
}

class OAuthClient {
  private _baseUri: string;

  constructor(baseUri: string) {
    this._baseUri = baseUri;
  }

  public login(username: string, password: string) {
    return fetch(`${this._baseUri}/connect/token`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formUrlEncodedSerialize({
        grant_type: "password",
        username,
        password,
        scope: "offline_access",
      }),
    });
  }

  public refresh(refresh_token: string) {
    return fetch(`${this._baseUri}/connect/token`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formUrlEncodedSerialize({
        grant_type: "refresh_token",
        refresh_token,
      }),
    });
  }
}

export default OAuthClient;
