import { compile } from "path-to-regexp";

interface IdParams {
  id: string;
}

export type RoleEditParams = IdParams;
export type UserEditParams = IdParams;
export type BuyerViewParams = IdParams;
export type ProductEditParams = IdParams;
export type BlogPostEditParams = IdParams;

const productEdit = "/product/edit/:id";
const roleEdit = "/role/edit/:id";
const buyerView = "/buyer/view/:id";
const userEdit = "/user/edit/:id";
const blogPostEdit = "/blogpost/edit/:id";

const routes = {
  login: "/auth/login",
  forgot: "/auth/forgot",
  users: "/user",
  userCreate: "/user/create",
  userEdit,
  userEditToPath: compile<UserEditParams>(userEdit),
  changePassword: "/user/changepassword",
  roles: "/role",
  roleCreate: "/role/create",
  roleEdit,
  roleEditToPath: compile<RoleEditParams>(roleEdit),
  products: "/product",
  buyers: "/buyer",
  buyerView,
  buyerViewToPath: compile<BuyerViewParams>(buyerView),
  productEdit,
  productEditToPath: compile<ProductEditParams>(productEdit),
  payments: "/payment",
  orders: "/order",
  blogPosts: "/blogpost",
  blogPostCreate: "/blogpost/create",
  blogPostEdit,
  blogPostEditToPath: compile<BlogPostEditParams>(blogPostEdit),
  home: "/",
  x404: "404",
};

export default routes;
