import { NormalizedCacheObject } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { from, split, ApolloLink } from "apollo-link";
import { createHttpLink, FetchOptions } from "apollo-link-http";
import { persistCache } from "apollo-cache-persist";
import { createApolloCache } from "./cache";
import { createAuthLink, typeDefs, authConfig, optionsConfig } from "./local";
import createErrorLink from "./error";
import { createUploadLink } from "apollo-upload-client";
import { OAuthClient } from "components/oauth";

interface ClientOptions {
  ssr?: boolean;
  oauthClient?: OAuthClient;
  linkOptions?: FetchOptions;
  multipartOperations?: Array<string | RegExp>;
}

async function createApolloClient({
  ssr = false,
  oauthClient,
  linkOptions,
  multipartOperations = [],
}: ClientOptions = {}): Promise<ApolloClient<NormalizedCacheObject>> {
  const links: ApolloLink[] = [];

  if (oauthClient) {
    links.push(createErrorLink(oauthClient));
  }

  links.push(createAuthLink());

  links.push(
    split(
      ({ operationName }) => {
        for (const operationPattern of multipartOperations) {
          if (typeof operationPattern === "string") {
            if (operationName === operationPattern) {
              return false;
            }
          } else {
            if (operationPattern.test(operationName)) {
              return false;
            }
          }
        }

        return true;
      },
      createHttpLink(linkOptions),
      createUploadLink(linkOptions)
    )
  );

  const cache = createApolloCache();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await persistCache({ cache, storage: localStorage as any, debug: true });

  const client = new ApolloClient({
    link: from(links),
    cache,
    typeDefs,
    ssrMode: ssr,
  });

  authConfig(client);
  optionsConfig(client);

  return client;
}

export default createApolloClient;
