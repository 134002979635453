import "moment/locale/ru";
import { ApolloProvider } from "@apollo/react-hooks";
import { ConfigProvider } from "antd";
import ruRu from "antd/es/locale/ru_RU";
import { GatsbyBrowser } from "gatsby";
import moment from "moment";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import React, { FC, useEffect, useState, useMemo } from "react";
import createApolloClient from "apollo";
import OAuthClientProvider, { OAuthClient } from "components/oauth";

interface AppProps {
  baseUrl: string;
  ssr?: boolean;
}

const App: FC<AppProps> = ({ children, baseUrl, ssr = false }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const oauthClient = useMemo(() => new OAuthClient(baseUrl), [baseUrl]);

  useEffect(() => {
    // eslint-disable-next-line import/no-named-as-default-member
    moment.locale("ru");
  }, []);

  useEffect(() => {
    (async () =>
      setClient(
        await createApolloClient({
          ssr,
          oauthClient,
          linkOptions: { uri: `${baseUrl}/graphql` },
          multipartOperations: [
            "AddProductImage",
            /^import.*$/i,
            "CreateBlogPost",
            "UpdateBlogPost",
          ],
        })
      ))();
  }, [baseUrl, oauthClient, ssr]);

  return (
    <OAuthClientProvider client={oauthClient}>
      <ConfigProvider locale={ruRu}>
        {(client && (
          <ApolloProvider client={client}>{children}</ApolloProvider>
        )) ||
          null}
      </ConfigProvider>
    </OAuthClientProvider>
  );
};

type WrapRootElement = GatsbyBrowser["wrapRootElement"];

export function wrapRootElementFactory(props: AppProps): WrapRootElement {
  const wrapRootElement: WrapRootElement = ({ element }) => {
    return <App {...props}>{element}</App>;
  };

  return wrapRootElement;
}

export default App;
