import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import idx from "idx";
import GET_SESSION from "./getSession.graphql";
import { GetSession } from "./types/GetSession";
import { useCallback, useMemo } from "react";

function useSession(options?: QueryHookOptions<GetSession>) {
  const { called, loading, data, ...result } = useQuery<GetSession>(
    GET_SESSION,
    options
  );

  const loaded = useMemo(() => called && !loading, [called, loading]);

  const findMenu = useCallback(
    (key: string) => {
      const menus =
        idx(
          data,
          x =>
            x.srv.session.getCurrentLoginInformations.userConfiguration.nav
              .menus
        ) || [];

      const menuPair = menus.find(x => x.key === key);
      return idx(menuPair, x => x.value);
    },
    [data]
  );

  const mainMenu = useMemo(() => findMenu("MainMenu"), [findMenu]);
  const mainMenuItems = useMemo(() => idx(mainMenu, x => x.items) || [], [
    mainMenu,
  ]);

  const user = useMemo(
    () => idx(data, x => x.srv.session.getCurrentLoginInformations.user),
    [data]
  );

  const userConfiguration = useMemo(
    () =>
      idx(
        data,
        x => x.srv.session.getCurrentLoginInformations.userConfiguration
      ),
    [data]
  );

  return {
    called,
    loading,
    loaded,
    data,
    user,
    userConfiguration,
    mainMenu,
    mainMenuItems,
    findMenu,
    ...result,
  };
}

export { GET_SESSION, useSession };
