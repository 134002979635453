import { setContext } from "apollo-link-context";
import idx from "idx";
import { ApolloContext } from "../types";
import { readAuthenticationInfo } from "./getAuthenticationInfo";

function createAuthLink() {
  return setContext((_, { cache, headers }: ApolloContext) => {
    const data = readAuthenticationInfo(cache);
    const accessToken = idx(data, x => x.authenticationInfo.accessToken);
    const tokenType = idx(data, x => x.authenticationInfo.tokenType);
    if (accessToken && tokenType) {
      return {
        headers: {
          ...headers,
          authorization: `${tokenType} ${accessToken}`,
        },
      };
    } else {
      return { headers };
    }
  });
}

export { createAuthLink };
